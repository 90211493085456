<template>
  <section class="q-pa-md">
    <h1>Groups</h1>
    <group-card
      v-for="value of data"
      :key="value.id"
      :value="value"
      class="q-mb-md"
    >
      <q-card-actions>
        <q-btn
          :href="`/${$route.params.locale}/group/${value.slug}`"
          color="primary"
          flat
          icon="fas fa-eye"
          label="View"
          type="a"
        />
      </q-card-actions>
    </group-card>
  </section>
</template>

<script>
  import { createNamespacedHelpers } from 'vuex'
  import GroupCard from 'pages/_locale/group/GroupCard'

  const moduleName = 'group'
  const { mapActions, mapState } = createNamespacedHelpers(
    moduleName,
  )

  export default {
    components: {
      GroupCard,
    },
    computed: {
      ...mapState(
        [
          'data',
          'meta',
        ],
      ),
    },
    meta: {
      title: 'Groups',
    },
    methods: {
      ...mapActions(
        [
          'index',
        ],
      ),
      async search () {
        return await this.index(
          this.meta,
        )
      },
    },
    data () {
      return {
        moduleName,
      }
    },
    mounted () {
      this.search()
    },
  }
</script>
